import React from "react";
import { Bars } from 'react-loader-spinner';
import Backdrop from "@material-ui/core/Backdrop";
import useStyles from "./appBarsloader";
import LinearWithValueLabel from '../LinearWithValueLabel';

const AppLoader = (props) => {
  const classes = useStyles();
  const isAllow = props.isAllow === false ? false : true;
  const { isAlgorithmProcessing, loadingDuration } = props;

  const actualDuration = isAlgorithmProcessing ? loadingDuration : 10000;

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <div>
        <Bars
          height='180'
          width='180'
          color='#1e8fff'
          ariaLabel='bars-loading'
          wrapperStyle={{}}
          // wrapperClass='loading-spinner'
          visible={true}
        />
        {isAllow && <LinearWithValueLabel
          isAlgorithmProcessing={isAlgorithmProcessing}
          loadingDuration={actualDuration}
        />}
      </div>
    </Backdrop>
  );
};

export default AppLoader;
