/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import { Chart } from 'react-google-charts'

export const options = {
  title: 'Pie Chart of diffrent Modalities that ran',
  is3D: true,
  'width':'100%',
  'height':300
}

export default function App(props) {
  const { getTenantsDetailsData } = props;
  const [showData, setShowData] = useState('');
  const [showText, setShowText] = useState(false);
  console.log("getTenantsDetailsData::", getTenantsDetailsData);

  useEffect(() => {
    if (getTenantsDetailsData !== undefined && getTenantsDetailsData.model_executed) {
      const mainData = [['Modalities', 'Number of modal ran']];
      getTenantsDetailsData.model_executed && getTenantsDetailsData.model_executed.length > 0 && getTenantsDetailsData.model_executed.map(data => {
       
        if (data.count === 0) {
          setShowText(true);
        } else {
          setShowText(false);
        }
        const arrayData = [];
        arrayData.push(`${data.name} (${data.count})`, data.count);
        mainData.push(arrayData);
      })
      setShowData(mainData);
    }
  }, [getTenantsDetailsData]);

  return (
    <div>
      <Chart
        chartType='PieChart'
        data={showData}
        options={options}
   
    />
    {showText && <h3 className='nodata'> No data to render</h3>}
    </div>
    
  )
}
