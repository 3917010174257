/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SuperAdminSidebar from './SuperAdminSidebar'
import './../../views/styles.css'
import { Box } from '@material-ui/core'
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import { getClients, addTenants } from '../../action/index';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import Viewicon from './../../images/vision.png';
import pendingicon from './../../images/notification.png';
import Tooltip from '@mui/material/Tooltip';

function openForm() {
  document.getElementById("myForm").style.display = "block";
}

function closeForm() {
  document.getElementById("myForm").style.display = "none";
}


const TenantDashboard = (props) => {
  const [tenantName, setTenantName] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showNameLengthError, setShowNameLengthError] = useState(false);
  const [showNameCharError, setShowNameCharError] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const gettenantData = useSelector((state) => state.getClientsData);
  const clientData = gettenantData.data
  const addTenantsData = useSelector((state) => state.addTenantsData.data);

  useEffect(() => {
    dispatch(getClients());
  }, [addTenantsData]);

  useEffect(() => {
    if (isEnabled) {
      if (gettenantData && gettenantData.status === "success") {
        toast.success("Tenant Created Successfully");
        setIsEnabled(false);
      } else {
        toast.error("Something went wrong");
        setIsEnabled(false);
      }
    };
  }, [gettenantData]);

  //Redirect to specific page
  const onRowClick = (row, url) => {
    Cookies.set('tenant_id', row.id);
    history.push(url);
  };

  /** 
  * @function isNameLowercase [Lowecase validation for tenant name]
  * @param {string} name
  */
  function isNameLowercase(name) {
    // Regular expression to check if the name contains uppercase letters
    const uppercaseRegex = /[A-Z]/;
    return !uppercaseRegex.test(name);
  };

  /** 
  * @function isNameValid [Validation for tenant name length]
  * @param {string} name
  */
  function isNameValid(name) {
    const minLength = 3;
    const maxLength = 63;
    const nameLength = name.length;
    return nameLength >= minLength && nameLength <= maxLength;
  };

  /** 
  * @function isNameValidChar [Special char validation for tenant name]
  * @param {string} name
  */
  function isNameValidChar(name) {
    // Regular expression to check if the name contains only alphabetic characters, numbers, and allowed special characters
    const validCharactersRegex = /^[a-zA-Z0-9\-_]+$/;
    return name === '' ? true : validCharactersRegex.test(name);
  };

  /** 
  * @function tenantNameChange [Handle change for tenant name]
  * @param {object} e
  */
  const tenantNameChange = (e) => {
    setShowNameError(!isNameLowercase(e.target.value));
    setTenantName(e.target.value);
    setShowNameLengthError(!isNameValid(e.target.value));
    setShowNameCharError(!isNameValidChar(e.target.value));
  };

  /** 
  * @function addTenant [Handle change for tenant name]
  */
  const addTenant = () => {
    const addTenantData = {
      'name': tenantName,
    };
    dispatch(addTenants(addTenantData));
    closeForm();
    setIsEnabled(true);
    setShowMessage(true);
    setTimeout(hideMesage, 30000);
  };

  const hideMesage = () => {
    setShowMessage(false);
  };

  /** 
  * @function capitalizeFirstLetter [Validation for first letter capital]
  * @param {string} string
  */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <SuperAdminSidebar />
      </Grid>
      <Grid item xs={12} sm={10}>
        <Box className='w100'>
          <Typography className='namelbl' variant='h5'>Tenant List</Typography>
        </Box>
        <div id="myForm" style={{ display: 'none' }}>
          <div className='wraperpopup'>
            <div className='txtrf17'><span className='cursor' onClick={closeForm}>&#10006;</span></div>
            <div className='inpw'>
              <label className='txt14'><b>Tenant Name </b></label>
              <div className='w100 mb10'>
                <TextField className='ptb0' id="outlined-basic" onChange={(e) => tenantNameChange(e)} />
                {(showNameError || showNameLengthError || showNameCharError) && <div id="file-filename-addTenant">
                  <div id="file-filename-add"><span class="dot"></span> Please enter the lowercase character.</div>
                  <div id="file-filename-add"><span class="dot"></span> Please enter the 3 to 63 character length.</div>
                  <div id="file-filename-add"><span class="dot"></span> Please do not enter special character.</div>
                </div>}
              </div>
            </div>
            <div className='btnwrp'><Button className='btnnw' variant="contained" disabled={showNameError || showNameLengthError || showNameCharError || tenantName === ''} onClick={addTenant}>Add</Button></div>

          </div>
        </div>
        {showMessage && <Alert severity="success" className='alertbox'>
          <Typography variant='p'>Tenant creation is in progress, In next 25-30 minutes this tenant will be ready to use.</Typography>
        </Alert>}
        <Box component="div" className='tableBox'>
          <Button className='btnnw btnspc' variant="contained" onClick={openForm}>Add Tenant</Button>
          <TableContainer component={Paper}>
            <Table className='tbl' aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell className='fbold'>Tenant Name</TableCell>
                  <TableCell className='fbold'>Audience</TableCell>
                  <TableCell className='fbold'>Status</TableCell>
                  <TableCell className='fbold'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientData && clientData.length && clientData.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {row.name}
                    </TableCell>

                    <TableCell>{row.audience}</TableCell>
                    {row.attributes ? <TableCell align='right'>{capitalizeFirstLetter(row.attributes.status ? row.attributes.status : '')}</TableCell> : <TableCell align='right'>N/A</TableCell>}
                    {(row.attributes.status === 'processing' || row.attributes.status === 'failed') ?
                      <TableCell sx={{ color: 'blue' }} align='right'>
                        <div className='cursor txtb algr' onClick={() => onRowClick(row, '/superadmin/pendingUsers')}>
                          <Tooltip title="Pending Users" placement="top">
                          <span className='pendingbtn'>
                              <img height="20px" src={pendingicon} alt="Pending Users" />
                          </span>
                        </Tooltip>
                        </div>
                      </TableCell> :
                      <TableCell className='cursor txtb algr'>
                        <div className='cursor txtb algr' onClick={() => onRowClick(row, '/superadmin/pendingUsers')}>
                        <Tooltip title="Pending Users" placement="top">
                          <span className='pendingbtn'>
                              <img height="20px" src={pendingicon} alt="Pending Users" />
                          </span>
                        </Tooltip>
                        </div>
                        <div className='cursor txtb algr' onClick={() => onRowClick(row, '/superadmin/tenantDetails')}>
                          <Tooltip title="View Details"placement="top" >
                            <span className='viewbtn' title="">
                              <img height="20px" src={Viewicon} alt="View Details" />
                            </span>
                          </Tooltip>
                        </div>
                      </TableCell>}
                  </TableRow>
                ))}
                {clientData && clientData.length === 0 && <div style={{ fontWeight: 'bolder', }}>No tenant is created</div>}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TenantDashboard;
