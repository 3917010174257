import { createAction } from "redux-actions";

import RestAPI from "../api/RestAPI";

const REQUEST_ALGOS = 'algo/REQUEST_ALGOS';

const initialState = {
    algos: []
};

export const reducer = (state = initialState, action) => {
    switch(action.type) {
            case REQUEST_ALGOS: {
                return { ...state, algos: action.payload}
            }
            default:
                return state;
    }
}

const Algorithm = (function() {
    const getAlgorithms = (state) => state.algo.algos

    const fetchAlgos = createAction(REQUEST_ALGOS, RestAPI.algoList);

    return {
        getAlgorithms,
        fetchAlgos
    }
})();

export default Algorithm