import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField as MTextField } from '@material-ui/core';

const TextField = ({ name, label, value, type, required, setValue, validate, autoFocus, autoComplete }) => {
  const [error, setError] = useState(undefined);
  const [touched, setTouched] = useState(false);
  return (
    <MTextField
      id={name}
      error={error && error.invalid}
      helperText={error ? error.message : ' '}
      margin="normal"
      fullWidth
      label={label}
      required={required}
      name={name}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      type={type}
      value={value}
      onBlur={() => {
        if (!touched) {
          setTouched(true);
        }
      }}
      onChange={(event) => {
        const newString = event.target.value;
        if (value !== newString) {
          setError(validate(name, newString));
          setValue(newString);
        }
      }}
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password']),
  value: PropTypes.string.isRequired, // perhaps this can be a number TOO
  setValue: PropTypes.func.isRequired,
  required: PropTypes.bool,
  validate: PropTypes.func,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
};

TextField.defaultProps = {
  required: false,
  autoComplete: undefined,
  type: 'text',
  autoFocus: false,
  validate: () => {},
};

export default TextField;
