import { useDispatch, useSelector } from 'react-redux';

import Organizations from '../redux/Organizations';


const useOrganizations = () => {
  const dispatch = useDispatch();

  const isFetchingOrganizations = useSelector(Organizations.getIsFetchingOrganizations);

  const activeOrganization = useSelector(Organizations.getActiveOrganization);

  const organizations = useSelector(Organizations.getOrganizations);

  const fetchOrganizations = () => {
    dispatch(Organizations.startFetch());
    return dispatch(Organizations.fetchOrganizations()).catch((err) => {
    });
  };

  const setActiveOrganization = (organizationId) => {
    dispatch(Organizations.setActiveOrganization(organizationId));
  };

  return {
    organizations,
    activeOrganization,
    isFetchingOrganizations,
    setActiveOrganization,
    fetchOrganizations,
  };
};

export default useOrganizations;
