import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useHistory } from "react-router-dom";

const drawerWidth = '15%';

//Sidebar menu
const sidebarMenu = [
  {
    label: 'Tenant',
    redirection: 'superadmin',
  }
]

export default function SuperAdminSidebar(props) {
  let history = useHistory();

  const redriectToURL = (url) => {
    history.push(`/${url}`);
  }

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant='permanent'
          sx={{
            width: drawerWidth,
            zIndex: 0,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto', marginTop: '' }}>
            <List className='sidebar'>
              {sidebarMenu.map((data) => (
                <ListItem key={data.label} disablePadding>
                  <ListItemButton>
                    <ListItemText className='sidetxt' onClick={() => redriectToURL(data.redirection)} primary={data.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              {['All mail', 'Trash', 'Spam'].map((text, index) => (
                <ListItem key={text} disablePadding sx={{ padding: 0 }}>
                  <ListItemButton sx={{ padding: 0 }}>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </div>
  )
}
