import axios from 'axios';
import Cookies from 'js-cookie'

/**
* API calling module
* @param {Object} httpObj [HTTP confirguration object]
* @param {Function} successHandler [Success Callback]
* @param {Function} errorHandler [Failure Callback]
* @return {Objet Callback} [Return Object Callback]
*/

const httpHelper = (httpObj, successHandler, errorHandler, isJSON = true) => {
    let serverUrl = '';
    if (typeof window.env !== "undefined") {
        serverUrl = window.env.REACT_APP_API_TARGET_URL;    
    }
    const token = Cookies.get('token');


    return axios.request({
        url: httpObj.url,
        method: httpObj.method || 'post',
        baseURL: serverUrl,
        headers: {
            ...{
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            ...httpObj.headers,
        },
        timeout: 1200000,
        data: httpObj.data,
        dataType: 'json',
    }).then(successHandler, errorHandler);
};

export default httpHelper;
