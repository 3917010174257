import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import Algorithm from '../redux/Algorithm';

const useAlgorithms = () => {
    const dispatch = useDispatch();

    const getAlgoList = useSelector(Algorithm.getAlgorithms)
    const requestAlgos = () => {
        dispatch(Algorithm.fetchAlgos())
    }
    const algoNameForId = (id) => {
        const algo = _.find(getAlgoList, {"id": id});

        return algo ? algo.algo_name + ' v' + algo.ver : "Unknown algorithm"
    }

    return {
        getAlgoList,
        requestAlgos,
        algoNameForId
    }
}

export default useAlgorithms