import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise';
import thunk from 'redux-thunk';
import * as Algorithm from './Algorithm';
import * as ImageEngine from './ImageEngine';
import * as Organizations from './Organizations';
import * as ImageData from './../reducer/Image';

// stateful things will live here
const appReducer = combineReducers({
  engine: ImageEngine.reducer,
  organizations: Organizations.reducer,
  algo: Algorithm.reducer,
  ImageData: ImageData.saveImagesDataReducer,
  fetchImageData: ImageData.fetchImagesDataReducer,
  AlgoData: ImageData.processImageApiDataReducer,
  processedImageData: ImageData.processedDataReducer,
  getClientsData: ImageData.getClientsDataReducer,
  addTenantsData: ImageData.addTenantsDataReducer,
  getTenantsDetailsData: ImageData.getTenantsDetailsDataReducer,
  GetPendingUser: ImageData.getPendingUsersReducer,
  approveUsers: ImageData.approvePendingUsersReducer,
  algoList: ImageData.getAlgorithmsReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const loadState = () => {
  const serializedState = localStorage.getItem('state'); // eslint-disable-line
  try {
    if (serializedState === null) {
      return {};
    }
    return {}; // JSON.parse(serializedState);
  } catch (err) {
    return {};
  }
};

const saveState = (state) => {
  // console.log("saveState:: state", state);
  // try {
  //   const serializedState = JSON.stringify(
  //     ...state
  //   );
  //   localStorage.setItem('state', serializedState); // eslint-disable-line
  // } catch (err) {
  //   console.log('Could not save state qweee', err); // eslint-disable-line
  // }
};

const persistedState = loadState();

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER' || action.type === 'REFRESH') {
    // This eventually should be stored to the USER account
    // activeSettings: state.activeSettings,
    return appReducer({}, action);
  }
  return appReducer(state, action);
};

const middleware = composeEnhancers(applyMiddleware(promiseMiddleware, thunk));

const store = createStore(rootReducer, persistedState, middleware);

store.subscribe(() => {
  saveState(store.getState());
});

export const getState = () => store.getState();

export default store;