import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

const ScrollReset = () => {
  const  location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line
      window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default ScrollReset;
