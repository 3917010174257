import httpHelper from './../helper/httpHelper';

/**
 * @function saveImagesData [Save Images Data]
 * @param {JSON Object} data
 */
export function saveImagesData(data = {}, Reset = false) {
    return async (dispatch) => {
        if (Object.keys(data).length > 0) {
            const request = {
                url: 'api/1.0.0/create_user_uploads',
                method: 'POST',
                data: data,
                dataType: 'json',
            };

            await httpHelper(request, (response) => {
                dispatch({ type: 'SAVE_IMAGE_SUCCESS', payload: response.data });
            }, (error) => {
                dispatch({ type: 'SAVE_IMAGE_ERROR', payload: error });
            });
        } else {
            dispatch({ type: 'SAVE_IMAGE__RESET', payload: {} });
        }
    };
}

/**
 * @function fetchImagesData [Fetch Images Data]
 * @param {JSON Object} data
 */
export function fetchImagesData(data, Rest = false) {
    const user_id = data;
    return async (dispatch) => {
            const request = {
                url: `api/1.0.0/list_user_uploads/${user_id}`,
                method: 'GET',
                dataType: 'json',
            };

            await httpHelper(request, (response) => {
                dispatch({ type: 'FETCH_IMAGE_SUCCESS', payload: response.data });
            }, (error) => {
                dispatch({ type: 'FETCH_IMAGE_ERROR', payload: error });
            });
    }
}

/**
 * @function processImageApi [Fetch Process Image Data]
 * @param {JSON Object} data
 */
export function processImageApi (data, Rest = false) {
    return async (dispatch) => {
        if (Object.keys(data).length > 0) {
            const request = {
                url: 'api/1.0.0/create_cancer_sample_uploads',
                method: 'POST',
                data: data,
                dataType: 'json',
            }
            return new Promise((resolve, reject) => {
                httpHelper(request, (response) => {
                    resolve(response);
                    //dispatch({ type: 'PROCESS_IMAGE_SUCCESS', payload: response.data })
                }, (error) => {
                    dispatch({ type: 'PROCESS_IMAGE_ERROR', payload: error })
                    reject(error);
                });
            });
        } else {
            dispatch({ type: 'PROCESS_IMAGE__RESET', payload: {} });
            return Promise.resolve();
        }
    }
}

/**
 * @function getProcessedImageData [Fetch Processed Images Data]
 * @param {JSON Object} data
 */
 export function getProcessedImageData(data, Rest = false) {
    return async (dispatch) => {
            const request = {
                url: 'api/1.0.0/list_processed_samples',
                method: 'GET',
                dataType: 'json',
            };

            await httpHelper(request, (response) => {
                dispatch({ type: 'PROCESSED_IMAGE_SUCCESS', payload: response.data });
            }, (error) => {
                dispatch({ type: 'PROCESSED_IMAGE_ERROR', payload: error });
            });
    }
}

export function getClients(data, Rest = false) {
    return async (dispatch) => {
            const request = {
                url: 'api/1.0.0/master/list_clients',
                method: 'GET',
                dataType: 'json',
            };

            await httpHelper(request, (response) => {
                dispatch({ type: 'GET_CLIENTS_SUCCESS', payload: response.data });
            }, (error) => {
                dispatch({ type: 'GET_CLIENTS_ERROR', payload: error });
            });
    }
}

export function addTenants(data, Rest = false) {
    return async (dispatch) => {
            const request = {
                url: 'api/1.0.0/master/create_clients',
                method: 'POST',
                data: data,
                dataType: 'json',
            };

            await httpHelper(request, (response) => {
                dispatch({ type: 'ADD_TENANTS_SUCCESS', payload: response.data });
            }, (error) => {
                dispatch({ type: 'ADD_TENANTS_ERROR', payload: error });
            });
    }
}

export function getTenantsDetails(data, Rest = false) {
    return async (dispatch) => {
            const request = {
                url: `/api/1.0.0/master/clients_detail/${data}`,
                method: 'GET',
                dataType: 'json',
            };

            await httpHelper(request, (response) => {
                dispatch({ type: 'GET_TENANTS_DETAILS_SUCCESS', payload: response.data });
            }, (error) => {
                dispatch({ type: 'GET_TENANTS_DETAILS_ERROR', payload: error });
            });
    }
}

/**
 * @function getPendingUsers [Fetch Pending Users Data]
 * @param {JSON Object} data
 */
 export function getPendingUsers (client_id, Reset = false) {
    return async (dispatch) => {
        if (!Reset) {
            const request = {
                url: `/api/1.0.0/master/clients/${client_id}/list_users?is_verified=false&provider=google`,
                method: 'GET',
                dataType: 'json',
            }
            await httpHelper(request, (response) => {
                dispatch({ type: 'GET_PENDING_USERS_SUCCESS', payload: response.data.data });
            }, (error) => {
                dispatch({ type: 'GET_PENDING_USERS_ERROR', payload: error });
            })
        } else {
            dispatch({ type: 'GET_PENDING_USERS_RESET', payload: {} });
        }
    }
}

/**
 * @function approvePendingUsers [approve the pending users]
 * @param {JSON Object} data
 */
 export function approvePendingUsers(clientId, userId, Reset = false) {
    return async (dispatch) => {
        if (!Reset) {
            const request = {
                url: `/api/1.0.0/master/clients/${clientId}/users_verify/${userId}/verify`,
                method: 'POST',
                dataType: 'json',
            };

            await httpHelper(request, (response) => {
                dispatch({ type: 'APPROVE_PENDING_USERS_SUCCESS', payload: response.data });
            }, (error) => {
                dispatch({ type: 'APPROVE_PENDING_USERS_ERROR', payload: error });
            });
        } else {
            dispatch({ type: 'APPROVE_PENDING_USERS_RESET', payload: {} });
        }
    };
}

/**
 * @function getAlgorithms [Fetch Algorithms Data]
 * @param {JSON Object} data
 */
 export function getAlgorithms (Reset = false) {
    return async (dispatch) => {
        if (!Reset) {
            const request = {
                url: `/api/1.0.0/list_algorithms?limit=1000`,
                method: 'GET',
                dataType: 'json',
            }
            await httpHelper(request, (response) => {
                dispatch({ type: 'GET_ALGORITHM_LIST_SUCCESS', payload: response.data });
            }, (error) => {
                dispatch({ type: 'GET_ALGORITHM_LIST_ERROR', payload: error });
            })
        } else {
            dispatch({ type: 'GET_ALGORITHM_LIST_RESET', payload: {} });
        }
    }
}

/**
 * @function saveAnnotationData [Save the annotations data]
 * @param {JSON Object} data
 */
export function saveAnnotationData(org_id, user_id, data, selectedId) {
    return async (dispatch) => {
        const request = {
            url: `/api/1.0.0/user_uploads_detail/${selectedId}`,
            method: 'PUT',
            dataType: 'json',
            data: {
                organization_id: org_id,
                user_id: user_id,
                meta: {
                    annotations: JSON.stringify(data)
                }
            },
        };

        try {
            const response = await httpHelper(request);
            dispatch({ type: 'SAVE_ANNOTATION_SUCCESS', payload: response.data });
        } catch (error) {
            dispatch({ type: 'SAVE_ANNOTATION_ERROR', payload: error });
        }
    };
}