import React, { useState, useEffect } from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} style={{height: '15px', width: '180px', borderRadius: '7px'}} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel({ isAlgorithmProcessing, loadingDuration }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const totalDuration = loadingDuration || 10000;
    const steps = 10;
    const interval = totalDuration / steps;
    
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer);
          return 100;
        }
        return prevProgress + (100 / steps);
      });
    }, interval);

    return () => clearInterval(timer);
  }, [loadingDuration, isAlgorithmProcessing]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
      <Typography variant="body2" color="white">In Progress...</Typography>
    </Box>
  );
}