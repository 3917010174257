import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#4A90E2',
    main: '#0F60FF',
    light: colors.blue[200],
  },
  secondary: {
    contrastText: white,
    dark: '#58585A',
    main: '#B2B2B2',
    light: '#E5E5E5',
  },
  error: {
    contrastText: white,
    dark: colors.grey[900],
    main: '#CF2026',
    light: colors.red[400],
  },
  warning: {
    main: '#f3A622',
  },
  success: {
    main: '#2AB697',
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    default: '#F2F2F2',
    paper: white,
  },
  divider: colors.grey[200],
};

export default palette
