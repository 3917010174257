import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import Isemail from 'isemail';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@material-ui/core';
import AuthCard from 'components/Cards/AuthCard';
// import { useSelector } from 'react-redux';
import TextField from 'components/Inputs/TextField';

const useStyles = makeStyles((theme) => ({
  instructions: {
    ...theme.typography.subtitle2,
  },
  title: {
    ...theme.typography.h5,
    marginBottom: theme.spacing(2),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: -20,
  },
  formGroupGrid: {
    paddingTop: theme.spacing(4),
  },
}));

const ForgotPassword = ({ gotoLogin, authError, onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const defaultUsername = ''; // useSelector(Selectors.getUserName);
  const [username, setUserName] = useState(defaultUsername);
  const [valid, setValid] = useState(!_.isEmpty(defaultUsername));

  const updateUserName = (name) => {
    setUserName(name);
    if (Isemail.validate(name)) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const validate = (name, value) => {
    if (name === 'username') {
      if (!value || value.length === 0) {
        setValid(false);
        return {
          invalid: true,
          message: t('required'),
        };
      }
      setValid(true);
    }
    return undefined;
  };
  return (
    <AuthCard
      errorMessage={authError && t(_.snakeCase(authError.errorMessage))}
      footer={
        <div className={classes.footer}>
          <Button size="small" onClick={gotoLogin}>
            {t('return_to_login')}
          </Button>
        </div>
      }
    >
      <Box component="div" className={classes.root}>
        <Typography className={classes.title}>{t('forgot_password')}</Typography>
        <Typography className={classes.instructions}>{t('reset_instructions')}</Typography>
      </Box>
      <TextField
        required
        validate={validate}
        name="username"
        value={username}
        label={t('username')}
        setValue={(v) => updateUserName(v)}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={!valid}
        className={classes.submit}
        onClick={() => onSubmit(username)}
      >
        {t('send_code')}
      </Button>
    </AuthCard>
  );
};

ForgotPassword.propTypes = {
  gotoLogin: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  authError: PropTypes.string,
};

ForgotPassword.defaultProps = {
  authError: undefined,
};

export default ForgotPassword;
