import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ConfigContext = React.createContext({});
const CONFIG_URL = '';

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState();
  useEffect(() => {
    const fetchConfig = async () => {
      const resp = await fetch(CONFIG_URL + '/api/1.0.0/.well-known/config.json');
      if (resp.ok) {
        const json = await resp.json();
        setConfig(json);
      }
    };

    if (!config) {
      fetchConfig();
    }
  }, [config]);

  return (
    <ConfigContext.Provider
      value={
        config
          ? {
              basePath: '',
              ...config.aud,
              publicURL: config.public_url,
            }
          : {}
      }
    >
      {children}
    </ConfigContext.Provider>
  );
};
ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ConfigProvider, ConfigContext };

