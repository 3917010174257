import {
  Dashboard
} from '../views';

import TenantDashboard from '../views/superAdmin/TenantDashboard';
import TenantDetails from '../views/superAdmin/TenantDetails';
import PendingUsers from '../views/superAdmin/PendingUsers';

const appRoutesSuperadmin = [
  { path: '/superadmin', component: TenantDashboard, exact: true, strict: true, type: 'master'},
  { path: '/superadmin/tenantDetails', component: TenantDetails, exact: true, strict: true, type: 'master' },
  { path: '/superadmin/pendingUsers', component: PendingUsers, exact: true, strict: true, type: 'master' },
];

const appRoutesUser = [
  { path: '/dashboard', component: Dashboard, exact: true, strict: true, type: 'user' },
  { path: '/', component: Dashboard, exact: false, strict: false, type: 'user' },
  
];

export { appRoutesSuperadmin, appRoutesUser };
