import crypto from 'crypto';
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import OAuth from '../api/OAuth';
import RestAPI from '../api/RestAPI';
import { useBasePath, useOrganizations } from '../hooks';
import { ConfigContext } from './ConfigContext';

const AuthContext = React.createContext();

// This performs a raw encoding
function base64URLEncode(str) {
  return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

function sha256(buffer) {
  return crypto.createHash('sha256').update(buffer).digest();
}

const AuthProvider = ({ children }) => {
  const [verifier] = useState(crypto.randomBytes(32));
  const [authCode, setAuthCode] = useState();
  const [reqToken, setReqToken] = useState();
  const [authError, setAuthError] = useState();
  const [loginPage, setLoginPage] = useState(false); //eslint-disable-line
  const [authToken, setAuthTokenState] = useState();
  const [userPrincipal, setUserPrincipalState] = useState();
  const [sessionLoading, setSessionLoading] = useState(true);
  const { setActiveOrganization } = useOrganizations(); //eslint-disable-line

  const updatedCodeVerifier = base64URLEncode(verifier);

  const dispatch = useDispatch();
  const history = useHistory();
  const { client_id, audience } = useContext(ConfigContext);
  const { p } = useBasePath();

  const setUserPrincipal = (user) => {
    if (!authError) {
      setUserPrincipalState(user);
      setActiveOrganization(user.organization.id);
    }
  };

  useEffect(() => {
    if (client_id) {
      OAuth.initialize(client_id, audience);
    }
  }, [client_id]); // eslint-disable-line

  // Try and get the Gravatar
  useEffect(() => {
    if (userPrincipal && userPrincipal.profile && userPrincipal.profile.email && !userPrincipal.profile.picture) {
      const pr = { ...userPrincipal };
      const hash = crypto
        .createHash('md5')
        .update(_.toLower(_.trim(userPrincipal.profile.email)))
        .digest('hex');
      pr.profile.picture = `https://www.gravatar.com/avatar/${hash}}?d=404`;
      setUserPrincipal(pr);
    }
  }, [userPrincipal]); // eslint-disable-line

  useEffect(() => {
    if (!client_id) {
      return;
    }

    const loginToken = Cookies.get('token');
   
    if (verifier && !reqToken && !authToken && !authError) {
      setSessionLoading(true);
      OAuth.oauthAuthorize({
        app_uri: p('/login'),
        redirect_uri: '/',
        code_challenge: base64URLEncode(sha256(base64URLEncode(verifier))),
      })
        .then((response) => {
          if (response.token) {
            setReqToken(response.token);
            setAuthError(response.error);
            setSessionLoading(false);
          }
          if (response.authCode) {
            setAuthCode(response.authCode);
            const code_verifier = base64URLEncode(base64URLEncode(verifier)); // eslint-disable-line
            const refreshVerifier = crypto.randomBytes(32);
            const newVerifier = crypto.randomBytes(32);
            OAuth.oauthTokenGet({
              code: response.authCode,
              code_verifier,
              refresh_verifier: base64URLEncode(refreshVerifier),
              refresh_nonce: base64URLEncode(sha256(base64URLEncode(newVerifier))), // get refresh verifier - generate at first
            })
              .then((tokenResp) => {
                if (tokenResp && tokenResp.token && tokenResp.token.id_token) {
                  setAuthToken(tokenResp.token, newVerifier);
                  Cookies.set('token', tokenResp.token.access_token);
                  return OAuth.userPrincipal(tokenResp.token.access_token);
                } else {
                  setAuthError('invalid_token');
                }
              })
              .then((user) => {
                if (user) {
                  setUserPrincipal(user);
                }
                setSessionLoading(false);
              })
              .catch((error) => {
                setSessionLoading(false);
                setAuthError('Invalid Permissions');
                throw error;
              });
          }
        })
        .catch(() => {
          setAuthError('Error Contacting Auth Server');
          setSessionLoading(false);
        });
    } else {
      setSessionLoading(false);
    }
  }, [verifier, reqToken, authToken, client_id]); // eslint-disable-line

  // useEffect(() => {
  //   const loginToken = Cookies.get('token');
  //   if(loginToken !== undefined) {
  //     OAuth.userPrincipal(loginToken).then((user) => {
  //       if (user) {
  //         setUserPrincipal(user);
  //         const newVerifier = crypto.randomBytes(32);
  //         setAuthToken(loginToken, newVerifier);
  //         history.push(window.location.pathname);
  //       }
  //       setSessionLoading(false);
  //     });
  //   }
  // }, [Cookies.get('token')]);

  // Returns false if the session was NOT refreshed
  const refreshSession = async (token, refreshVerifier) => {
    if (token === undefined) {
      return false;
    }
    const accessTokenValue = (token.access_token) ? token.access_token : token;
    if (moment().unix() > parseJwt(accessTokenValue).exp) {
      const newVerifier = crypto.randomBytes(32);
      try {
        const tokenResp = await OAuth.oauthTokenRefresh({
          refresh_token: token.refresh_token,
          refresh_verifier: base64URLEncode(refreshVerifier),
          refresh_nonce: base64URLEncode(sha256(base64URLEncode(newVerifier))), // get refresh verifier - generate at first
        });
        if (tokenResp) {
          setAuthToken(tokenResp.token, newVerifier);
          Cookies.set('token', tokenResp.token.access_token);
          if (tokenResp.error !== undefined) {
            logout();
            return false;
          }
          return { token: tokenResp.token, refreshVerifier: newVerifier };
        }
      } catch (e) {
        logout();
        return false;
      }
    }
    return false;
  };
  const setLoggingIn = async (loggingInPage) => {
    setLoginPage(loggingInPage);
  };
  const setAuthToken = async (token, refreshVerifier) => {
    RestAPI.initialize(token, refreshSession, refreshVerifier); // eslint-disable-line
    setAuthTokenState(token);
  };

  const parseJwt = (token) => {
    const base64Url = token && token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  const login = async (username, password) => {
    try {
      const tokenResponse = await OAuth.oauthAuthorize({
        app_uri: p('/login'),
        redirect_uri: '/',
        code_challenge: base64URLEncode(sha256(base64URLEncode(verifier))),
      });
      let _reqToken = reqToken;
      if (tokenResponse.token) {
        _reqToken = tokenResponse.token;
        setReqToken(tokenResponse.token);
      } else if (tokenResponse.error) {
        setAuthError(tokenResponse.error);
        return { error: tokenResponse.error };
      }

      if (_reqToken) {
        const code_verifier = base64URLEncode(verifier); // eslint-disable-line
        const loginBody = {
          request_token: _reqToken,
          login: username,
          password,
          code_verifier,
          login_uri: p('/login'),
        };
        const response = await OAuth.login(loginBody);
        if (response.authCode) {
          setAuthCode(response.authCode);
          setAuthError(response.error);
          if (response.error === null) {
            // const currentVerifier = refreshVerifier;
            const newVerifier = crypto.randomBytes(32);
            console.log(":: Login Calling");
            const tokenResp = await OAuth.oauthTokenGet({
              code: response.authCode,
              code_verifier,
              refresh_verifier: base64URLEncode(newVerifier),
              refresh_nonce: base64URLEncode(sha256(base64URLEncode(newVerifier))), // get refresh verifier - generate at first
            });
            if (tokenResp && tokenResp.token) {
              setAuthToken(tokenResp.token, newVerifier);
              Cookies.set('token', tokenResp.token.access_token);
            } else {
              return { error: 'invalid_token' };
            }
            const user = await OAuth.userPrincipal(tokenResp.token.access_token);
            if (user) {
              setUserPrincipal(user);
            }
          }
        }
        return response;
      }
      if (authCode) {
        return { authCode };
      }
    } catch (error) {
      setSessionLoading(false);
      setAuthError(error.statusText);
      return { error: error.statusText };
    }
    return { error: 'failed' };
  };

  /**
  * function ssoLogin to handle the login
  * @param {string} token [string value]
  * @return {string} [Return Object Callback]
  */
  const ssoLogin = async (token) => {
    const user = await OAuth.userPrincipal(token);
    if (user) {
      setUserPrincipal(user);
      const newVerifier = crypto.randomBytes(32);
      setAuthToken(token, newVerifier);
    }
  }

  const logout = async () => {
    const baseUrl = window.env.REACT_APP_API_TARGET_URL;
    const domainName = baseUrl.replace(/^https?:\/\//, '');
    await OAuth.logout(p('/'));
    setAuthCode();
    setAuthError();
    setReqToken();
    setAuthToken();
    Cookies.remove('token');
    Cookies.remove('user_id');
    Cookies.remove('org_id');
    Cookies.remove('user_roles');
    Cookies.remove('tenant_id');
    document.cookie = `_atomic_session#${domainName}#id=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.viewsmltechnologies.com;`;
    document.cookie = `_atomic_session#${domainName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=.viewsmltechnologies.com;`;
    history.push(p('/login'));
    dispatch({ type: 'LOGOUT_USER' });
  };

  const resetPassword = async (userLogin) => {
    if (userLogin === undefined) {
      userLogin = userPrincipal.login;
    }
    const params = {
      client_id: client_id,
      login: userLogin,
      notify: 'email',
      redirect_uri: '/', // p('/login'), // userLogin ? memberBasePath : p('/'),
      type: 'reset',
    };
    if (authToken === undefined) {
      const response = await OAuth.oauthAuthorize({
        app_uri: p('/login'),
        redirect_uri: '/',
        code_challenge: base64URLEncode(sha256(base64URLEncode(verifier))),
      });
      if (response.token) {
        params.request_token = response.token;
        params.code_verifier = base64URLEncode(base64URLEncode(verifier)); // eslint-disable-line
      }
    }
    try {
      const response = await OAuth.oauthPassword(params); // , authToken && authToken.access_token);
      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        setAuthError,
        setLoggingIn,
        authCode,
        authError,
        sessionValid: !!authToken,
        accessToken: authToken && authToken.access_token,
        sessionLoading,
        resetPassword,
        user: userPrincipal,
        requestToken: reqToken,
        verifier: updatedCodeVerifier,
        ssoLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider, AuthContext };

