/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import SuperAdminSidebar from './SuperAdminSidebar'
import './../../views/styles.css'
import { Box } from '@material-ui/core'
import Typography from '@mui/material/Typography';
import { approvePendingUsers, getPendingUsers } from '../../action/index';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AppLoader from './../../views/dashboard/appLoader/AppLoader';
import Cookies from 'js-cookie';

const PendingUsers = (props) => {
    const dispatch = useDispatch();
    const [isLoader, setLoader] = useState(false);
    const [clientId, setSetClientId] = useState('');
    const pendingUsers = useSelector((state) => state.GetPendingUser.data);
    const approveUsers = useSelector((state) => state.approveUsers);

    useEffect(() => {
        const tenant_id = Cookies.get('tenant_id');
        if (tenant_id) {
            setSetClientId(tenant_id);
            setLoader(true);
            dispatch(getPendingUsers(tenant_id));
        };
    }, []);

    useEffect(() => {
        if (pendingUsers) {
            setLoader(false);
        };
    }, [pendingUsers]);

    useEffect(() => {
        if (approveUsers && approveUsers.status === "success" && approveUsers.data) {
            toast.success(approveUsers.data.message);
            dispatch(approvePendingUsers('', '', true));
            dispatch(getPendingUsers(clientId));
        } else if (approveUsers && approveUsers.data) {
            toast.error(approveUsers.data.message);
        }
    }, [approveUsers]);

    /** 
    * @function handleApproveUsers [Handle user for approval]
    */
    const handleApproveUsers = (row) => {
        setLoader(true);
        dispatch(approvePendingUsers(clientId, row.id));
    };

    return (
        <Grid container spacing={2}>
            {isLoader && <AppLoader isAllow={false} />}
            <Grid item xs={12} sm={2}>
                <SuperAdminSidebar />
            </Grid>
            <Grid item xs={12} sm={10}> 
                <Box className='w100'>
                    <Typography className='namelbl' variant='h5'>Pending Users</Typography>
                </Box>
                <Box component="div" className='tableBox'>
                    <TableContainer component={Paper} style={{ marginTop: '60px' }}>
                        <Table className='tbl' aria-label='simple table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='fbold'>Name</TableCell>
                                    <TableCell className='fbold'>Email</TableCell>
                                    <TableCell className='fbold'>Provider</TableCell>
                                    <TableCell className='fbold'>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pendingUsers && pendingUsers.length > 0 && pendingUsers.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component='th' scope='row'>
                                            {row.display_name}
                                        </TableCell>
                                        <TableCell>{row.login}</TableCell>
                                        <TableCell>{row.provider}</TableCell>
                                        {<TableCell className='cursor txtb algr' onClick={() => handleApproveUsers(row)}>Approve</TableCell>}
                                    </TableRow>
                                ))}
                                {pendingUsers && pendingUsers.length === 0 && <div style={{ fontWeight: 'bolder', float: 'left', height: '30px' }}>Sorry, There are no pending users</div>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </Grid>
    )
}

export default PendingUsers;
