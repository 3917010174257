/* eslint-disable */
import palette from '../palette';

export default {
  root: {
    backgroundColor: palette.background.default,
  },
  input: {
    paddingTop: 12,
  },
};
