import { useContext } from 'react';
import { ConfigContext } from '../context/ConfigContext';

const useBasePath = () => {
  const { basePath, config } = useContext(ConfigContext);
  const p = (path) => {
    if (path.length === 0) {
      return basePath;
    }
    return basePath + path;
  };

  return {
    p,
    basePath,
    publicUrl: config && config.public_url,
  };
};

export default useBasePath;
