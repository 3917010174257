import { useDispatch, useSelector } from "react-redux";

import ImageEngine from "../redux/ImageEngine";

const useImageEngine = () => {
    const dispatch = useDispatch();

    const getIsLoading       = useSelector(ImageEngine.getLoading);
    const getProcessedImages = useSelector(ImageEngine.getProcessed);
    const getRequestedImages = useSelector(ImageEngine.getRequested);
    const getImageErrors     = useSelector(ImageEngine.getError);
    const listImages         = useSelector(ImageEngine.getAllImages);
    const listSaveImages     = useSelector(ImageEngine.getAllSaveImages);
    const activeImage        = useSelector(ImageEngine.getActiveImage);

    const requestImagesFromServerAsync = () => {
        if (!getIsLoading) {
            dispatch(ImageEngine.startRequest());
            return dispatch(ImageEngine.fetchImages());
        }
    }

    const uploadImageDetails = (data) => {
        dispatch(ImageEngine.startRequest());
        return dispatch(ImageEngine.saveImages(data));
    }

    const uploadImageToServerAsync = (image) => {
        return dispatch(ImageEngine.uploadImage(image)).then((resp) => {
            requestImagesFromServerAsync();
        });
    }

    const requestImageDetail = (image_id) => {
        return dispatch(ImageEngine.getImageDetail());
    }

    const setActiveImage = (image) => {
        return dispatch(ImageEngine.setActiveImage(image));
    }

    return {
        getIsLoading,
        getProcessedImages,
        getRequestedImages,
        getImageErrors,
        activeImage,
        listImages,
        listSaveImages,
        requestImageDetail,
        requestImagesFromServerAsync,
        uploadImageToServerAsync,
        setActiveImage,
        uploadImageDetails,
    }
};

export default useImageEngine;