import useImageEngine from '../hooks/useImageEngine';
import { saveImagesData } from './../action/index';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

const { BlobServiceClient } = require('@azure/storage-blob')

let globalBlob = undefined

const useFileUpload = () => {
  const dispatch = useDispatch();
  const getServiceBlob = () => {
    if (!!globalBlob) {
      return globalBlob
    }
    const blobServiceClient = new BlobServiceClient(
      window.env.REACT_APP_BLOB_SERVICE_SAS_URL
    )
    globalBlob = blobServiceClient
    return globalBlob
  }

  const uploadFile = async (props) => {
    let azurePromiseArray = [];
    const inputFileArray = Array.from(props.imageFile);
    const promiseCall = async (returnData) => {
      const file = returnData;
      const blobServiceClient = getServiceBlob()
      const user_id = Cookies.get('user_id');
      const org_id = Cookies.get('org_id');
      const azureContainerName = window.env.REACT_APP_CONTAINER_NAME;
      const containerClient = blobServiceClient.getContainerClient(
        `${azureContainerName}/${user_id}/unstained`
      );
      const blockBlobClient = containerClient.getBlockBlobClient(file.name);
      const blob_upload_start_time = new Date().toISOString();
      const uploadResp = await blockBlobClient.uploadBrowserData(file);
      const blob_upload_end_time = uploadResp ? new Date().toISOString() : null ;
      const storageName = window.env.REACT_APP_STORAGE_NAME;
      const containerName = window.env.REACT_APP_CONTAINER_NAME;
      const imgFileName = file.name;
      const createURL = `https://${storageName}.blob.core.windows.net/${containerName}/${imgFileName}`
      uploadResp.createdImgURL = createURL;
      uploadResp.file_name = `${imgFileName}`;
      let fileType = file.type;
      if(!fileType) {
        const splitData = file.name.split('.');
        fileType = splitData[(splitData.length-1)];
      }

      const paramData = {
        "user_id": user_id,
        "organization_id": org_id,
        "file_location": `/${azureContainerName}/${user_id}/unstained/${imgFileName}`,
        "file_format": fileType,
        "file_name": imgFileName,
        "file_uri": uploadResp.createdImgURL,
        "request_id": uploadResp.requestId,
        "client_request_Id": uploadResp.clientRequestId,
        "image_desc": props.image_desc,
        "image_tag": props.image_tag,
        "blob_upload_start_time": blob_upload_start_time,
        "blob_upload_end_time": blob_upload_end_time,
        "file_size": file.size,
        "meta": {
          "height": props.height,
          "width": props.width
        }
      }

      return new Promise((resolve, reject) => {
        if (uploadResp && Object.keys(uploadResp).length > 0 && paramData) {
          resolve(paramData)
        } else {
          reject("Something went wrong")
        }
      });
    };

    inputFileArray.forEach((data) => {
      let promiseReturnFunction = promiseCall(data);
      azurePromiseArray.push(promiseReturnFunction);
    });

    Promise.all(azurePromiseArray).then((result) => {
      if (result && result.length > 0) {
        dispatch(saveImagesData({"uploads" : result}));
      }
      }).catch((error) => {
      if (error) {
        dispatch(saveImagesData(saveImagesData({}, true)));
      }
      });
  }

  return {
    uploadFile
  }
};

export default useFileUpload;
