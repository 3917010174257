import { Auth, HeaderContainer } from '../containers';
const indexRoutes = [
  { path: '/login', container: Auth, requireAuth: false },
  { path: '/register', container: Auth, requireAuth: false },
  { path: '/', container: HeaderContainer, requireAuth: true },
  { path: '/dashboard', container: HeaderContainer, requireAuth: true },
  { path: '/superadmin', container: HeaderContainer, requireAuth: true },
];

export { indexRoutes }; // eslint-disable-line

