/* eslint-disable */
import palette from '../palette';

export default {
  root: {
    color: palette.icon,
    minWidth: 32,
    paddingLeft: 16,
  },
};
