/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid';
import SuperAdminSidebar from './SuperAdminSidebar'
import './../../views/styles.css'
import { getTenantsDetails } from '../../action/index';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import AlgoPiChart from './AlgoPiChart';
import ModalPiChart from './ModalPiChart';
import AppLoader from '../dashboard/appLoader/AppLoader';

export default function TenantDetails(props) {
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const getTenantsDetailsStateData = useSelector((state) => state.getTenantsDetailsData);
  const getTenantsDetailsData = getTenantsDetailsStateData.data
  const isAllow = false;
  let totalConsumption = 0;

  useEffect(() => {
    const tenant_id = Cookies.get('tenant_id');
    if (tenant_id) {
      dispatch(getTenantsDetails(tenant_id));
      setShowLoader(true)
    };
  }, []);

  useEffect(() => {
    const loderTime = setTimeout(() => {
      if (getTenantsDetailsStateData && getTenantsDetailsStateData.status === "success") {
        setShowLoader(false)
      }
    }, 1000);
    if (!showLoader) {
      clearTimeout(loderTime);
    }
  }, [getTenantsDetailsData]);
  if(getTenantsDetailsData) {
    totalConsumption = getTenantsDetailsData.total_space_consumed ? (getTenantsDetailsData.total_space_consumed/1000000).toFixed(2) : 0;
  }

  return (
    <>
      <Grid container spacing={1} >
        <Grid item sm={2}>
          <SuperAdminSidebar />
        </Grid>
        {showLoader && <AppLoader isAllow={isAllow} />}
        <Grid item sm={10}>
          <Box sx={{ width: '100%' }}>
            <Typography className='namelbl' variant='h5'>Tenant Details</Typography>
          </Box>
          <Grid container spacing={2} sx={{ marginTop: '3%', width: '99%' }}>
            <Grid item sm={3}>
              <Card className='cardBox'
                sx={{ border: '#b8b5b5 1px solid', boxShadow: '#ddd 0px 0px 9px 3px', height: '170px' }}
              >
                <CardContent>
                  <Typography variant='h1' className="cardHead" color="text.secondary" gutterBottom>
                    Number of Users
                  </Typography>
                  <Typography variant='h1' className="cardtext" color="text.secondary" gutterBottom>
                    {getTenantsDetailsData ? getTenantsDetailsData.number_of_users : ''}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={3}>
              <Card className='cardBox'
                sx={{ border: '#b8b5b5 1px solid', boxShadow: '#ddd 0px 0px 9px 3px', height: '170px' }}
              >
                <CardContent>
                  <Typography variant='h1' className="cardHead" color="text.secondary" gutterBottom>
                    Number of time Alogs are Fired
                  </Typography>
                  <Typography variant='h1' className="cardtext" color="text.secondary" gutterBottom>
                    {getTenantsDetailsData ? getTenantsDetailsData.algo_triggered : ''}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={3}>
              <Card className='cardBox'
                sx={{ border: '#b8b5b5 1px solid', boxShadow: '#ddd 0px 0px 9px 3px', height: '170px' }}
              >
                <CardContent>
                  <Typography variant='h1' className="cardHead" color="text.secondary" gutterBottom>
                    Number of images that are uploaded
                  </Typography>

                  <Typography variant='h1' className="cardtext" color="text.secondary" gutterBottom>
                    {getTenantsDetailsData ? getTenantsDetailsData.number_of_uploads : ''}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={3}>
              <Card className='cardBox'
                sx={{ border: '#b8b5b5 1px solid', boxShadow: '#ddd 0px 0px 9px 3px', height: '170px' }}
              >
                <CardContent>
                  <Typography variant='h1' className="cardHead" color="text.secondary" gutterBottom>
                    Total Space Consumed
                  </Typography>
                  <Typography variant='h1' className="cardtext" color="text.secondary" gutterBottom>
                    {totalConsumption} MB
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid container spacing={1} >
                <Grid item sm={6}><div className='chartwrap'><AlgoPiChart getTenantsDetailsData={getTenantsDetailsData} /></div></Grid>
                <Grid item sm={6}><div className='chartwrap'> <ModalPiChart getTenantsDetailsData={getTenantsDetailsData} /></div> </Grid>

        </Grid>
           
           
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
