import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999999999999,
    color: "#fff",
  },
}));

export default useStyles;
